import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from '@mui/material';
import styled from '@emotion/styled';
import { Line, LinePath } from '@visx/shape';
import { curveNatural } from '@visx/curve';
import { Fragment, useCallback, useMemo, useState, } from 'react';
import { scaleTime, scaleLinear, } from '@visx/scale';
import { extent } from '@visx/vendor/d3-array';
import { useTooltip, TooltipWithBounds } from '@visx/tooltip';
import { MarkerCircle } from '@visx/marker';
import { AxisBottom, AxisRight } from '@visx/axis';
import { format } from 'date-fns';
import { GridColumns, GridRows } from '@visx/grid';
import { localPoint } from '@visx/event';
import { useParentSize } from '@visx/responsive';
import { Group } from '@visx/group';
import { getLocale } from '@src/shared/lib/DateTime';
import { useAppSelector } from '@app/store/Hooks';
import { ContainerColumn } from '@components/styled';
import { defineCurveOpacity, defineDotsTree, defineTooltipPlacement, getDefaultHoveredGraphsObj, } from './utils/utils';
import PointsTree from './utils/PointsTree';
import TooltipElement from './TooltipElement';
import { BottomAxisHeight, CurveAxisGap, GraphMinHeight, GraphMinWidth, GridLineAdditionalLength, Margin, RightAxisWidth, } from './constants';
const WalletsProfitContainer = styled(ContainerColumn)(() => ({
    position: 'relative',
}));
const Svg = styled.svg(() => ({
    width: '100%',
    height: 'inherit',
    overflow: 'visible',
}));
const CursorRect = styled.rect(() => ({
    fill: 'transparent',
}));
const TooltipStyled = styled(TooltipWithBounds, { shouldForwardProp: propName => propName !== 'isVisible' && propName !== 'gap' })(props => ({
    '&.visx-tooltip': {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        gap: `${props.theme.spacing_sizes.xs * 0.5}px ${props.theme.spacing_sizes.s}px`,
        visibility: props.isVisible ? 'visible' : 'hidden',
        backgroundColor: 'transparent',
        color: '#fff',
        pointerEvents: 'none',
        fontSize: 14,
        letterSpacing: '-0.084px',
        lineHeight: '20px',
        maxHeight: 236,
    },
}));
const tooltipStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
};
const TooltipDotCircle = styled.circle(props => ({
    fill: '#FFFFFF',
    pointerEvents: 'none',
    stroke: props.theme.palette.primary.dark,
    strokeWidth: 2,
    visibility: props.isVisible ? 'visible' : 'hidden',
    transform: `translate(${props.x || 0}px, ${props.y || 0}px)`,
}));
const TooltipDotRect = styled('rect', { shouldForwardProp: propName => propName !== 'isVisible' && propName !== 'x' && propName !== 'y' })(props => ({
    fill: '#FFFFFF',
    pointerEvents: 'none',
    stroke: props.theme.palette.primary.dark,
    strokeWidth: 2,
    visibility: props.isVisible ? 'visible' : 'hidden',
    transform: `translate(${props.x - 3}px, ${props.y - 7}px)`,
    rx: 3,
    width: 6,
    height: 14,
}));
const getWalletsColors = (theme) => [
    theme.customColors.graphs.wallets_profit_graph.colors[0],
    theme.customColors.graphs.wallets_profit_graph.colors[1],
    theme.customColors.graphs.wallets_profit_graph.colors[2],
    theme.customColors.graphs.wallets_profit_graph.colors[3],
];
const WalletsProfitGraph = ({ walletsData, }) => {
    var _a;
    const theme = useTheme();
    const { language } = useAppSelector((state) => state.app);
    const [hoveredKey, setHoveredKey] = useState(() => getDefaultHoveredGraphsObj(walletsData));
    const { parentRef, width, height } = useParentSize({
        initialSize: {
            width: GraphMinWidth,
            height: GraphMinHeight,
        },
    });
    const curveWidth = width > 0 ?
        width - Margin.left - Margin.right - CurveAxisGap - RightAxisWidth :
        0;
    const curveHeight = height > 0 ?
        height - Margin.top - Margin.bottom - CurveAxisGap - BottomAxisHeight :
        0;
    const colors = useMemo(() => getWalletsColors(theme), [theme]);
    const getX = (pointCoords) => pointCoords.date;
    const getY = (pointCoords) => pointCoords.percent;
    const { tree: walletDotsTree, } = useMemo(() => defineDotsTree(walletsData), [walletsData]);
    const longestDataKey = useMemo(() => {
        var _a;
        let maxKey = 0;
        let max = 0;
        if ((_a = Object.keys(walletsData)) === null || _a === void 0 ? void 0 : _a.length) {
            Object.keys(walletsData).forEach((walletKey) => {
                if (max < walletsData[Number(walletKey)].length) {
                    maxKey = Number(walletKey);
                }
                max = Math.max(max, walletsData[Number(walletKey)].length);
            });
        }
        return maxKey;
    }, [walletsData]);
    const scaleX = useMemo(() => scaleTime({
        domain: extent(walletsData[longestDataKey], getX),
        range: [0, curveWidth],
        nice: false,
    }), [
        walletsData,
        longestDataKey,
        curveWidth,
    ]);
    const scaleY = useMemo(() => scaleLinear({
        domain: extent(walletsData[0], getY),
        range: [curveHeight, 0],
        nice: true,
    }), [
        walletsData,
        curveHeight,
    ]);
    const scaleYFormat = (ratio) => `${ratio * 100} %`;
    const scaleXFormat = (date) => format(date, 'dd MMM', { locale: getLocale(language || 'en') });
    const { hideTooltip, showTooltip, tooltipLeft, tooltipTop, tooltipOpen, tooltipData, } = useTooltip({});
    const handleTooltipHide = useCallback(() => {
        hideTooltip();
        setHoveredKey(getDefaultHoveredGraphsObj(walletsData));
    }, [
        hideTooltip,
        walletsData,
    ]);
    const handleTooltipPosition = useCallback((event) => {
        const { x, y } = localPoint(event) || { x: 0, y: 0 };
        const treeResult = [];
        PointsTree.defineClosestElements({
            matchedNodes: treeResult,
            node: walletDotsTree,
            scaleFns: {
                scaleX,
                scaleY,
            },
            searchPoint: [x - Margin.left, y - Margin.top],
        });
        if (treeResult.length) {
            let gap = [];
            const { left, top, xGap, yGap, ellipseLeft, ellipseTop, } = defineTooltipPlacement({
                elementsNumber: treeResult.length,
                points: treeResult,
                scaleFns: {
                    scaleX,
                    scaleY,
                },
                curveWidth,
                curveHeight,
            });
            gap = [xGap, yGap];
            setHoveredKey((prev) => {
                const prevKeys = prev;
                showTooltip({
                    tooltipData: {
                        xCoord: scaleX(treeResult[0].date) + Margin.left,
                        yCoord: scaleY(treeResult[0].percent) + Margin.top,
                        elements: treeResult.map((value) => {
                            prevKeys[value.walletKey] = true;
                            return {
                                amount: value.value,
                                percent: value.percent * 100,
                                portfolioName: 'Binance',
                                value: value.value,
                                walletKey: value.walletKey,
                                showPercentage: !value.isIndexPoint,
                            };
                        }),
                        isMultiple: treeResult.length > 1,
                        xGap: (gap === null || gap === void 0 ? void 0 : gap[0]) || 0,
                        yGap: (gap === null || gap === void 0 ? void 0 : gap[1]) || 0,
                        ellipseLeft: ellipseLeft + Margin.left,
                        ellipseTop: ellipseTop + Margin.top,
                    },
                    tooltipLeft: left + Margin.left,
                    tooltipTop: top + Margin.top,
                });
                return prevKeys;
            });
        }
        else {
            handleTooltipHide();
        }
    }, [
        showTooltip,
        scaleX,
        scaleY,
        curveWidth,
        curveHeight,
        handleTooltipHide,
        walletDotsTree,
    ]);
    return (_jsxs(WalletsProfitContainer, { ref: parentRef, children: [_jsxs(Svg, { viewBox: `0 0 ${width} ${height}`, width: '100%', height: '100%', children: [_jsx(GridColumns, { scale: scaleX, left: Margin.left, 
                        // NOTE: adding aditional length and not using top margin
                        // in order to create an effect of extended grid
                        // that goes beyond the graph related grid points
                        height: curveHeight + GridLineAdditionalLength, numTicks: 7, stroke: '#EFF1F3', strokeDasharray: '4 4' }), _jsx(GridRows, { scale: scaleY, top: Margin.top, 
                        // NOTE: adding aditional length and halfing left margin
                        // in order to create an effect of extended grid
                        // that goes beyond the graph related grid points
                        left: Margin.left / 2, width: curveWidth + GridLineAdditionalLength, numTicks: 7, stroke: '#EFF1F3', strokeDasharray: '4 4' }), _jsxs(Group, { top: Margin.top, left: Margin.left, width: curveWidth, height: curveHeight, children: [((_a = Object.keys(walletsData)) === null || _a === void 0 ? void 0 : _a.length) && Object.keys(walletsData)
                                .map((dataKey) => (_jsxs(Fragment, { children: [_jsx(MarkerCircle, { id: `marker-circle-${dataKey}`, fill: colors[Number(dataKey)], markerUnits: 'userSpaceOnUse', orient: 'auto', size: 4, cx: 4, cy: 4, refX: 4, refY: 4 }, `marker-circle-${dataKey}`), _jsx(LinePath, { x: (d) => scaleX(getX(d)), y: (d) => scaleY(getY(d)), curve: curveNatural, data: walletsData[Number(dataKey)], stroke: colors[Number(dataKey)], markerEnd: `url(#marker-circle-${dataKey})`, markerMid: `url(#marker-circle-${dataKey})`, markerStart: `url(#marker-circle-${dataKey})`, opacity: defineCurveOpacity(hoveredKey, dataKey) }, `${dataKey}-curve`)] }, `curve-group-${dataKey}`))), _jsx(CursorRect, { width: curveWidth, height: curveHeight, onTouchStart: handleTooltipPosition, onTouchMove: handleTooltipPosition, onMouseMove: handleTooltipPosition, onMouseLeave: handleTooltipHide })] }), _jsx(AxisRight, { top: Margin.top, left: Margin.left + curveWidth + CurveAxisGap - Margin.right, scale: scaleY, tickFormat: (value) => scaleYFormat(value), tickLabelProps: {
                            fontSize: 14,
                            fontWeight: 400,
                            letterSpacing: '-0.084px',
                            lineHeight: '20px',
                            fill: '#ABB4BB',
                            // NOTE: changes in x, width and textAnchor in combination
                            // give us a right-aligned text.
                            x: RightAxisWidth + CurveAxisGap,
                            // TODO: need to figure out why width itself isn't enought to fit a text
                            // so only by using x2 of width the text fits properly.
                            width: RightAxisWidth * 2,
                            textAnchor: 'end',
                        }, numTicks: 7, hideAxisLine: true, hideTicks: true }), _jsx(AxisBottom, { top: Margin.top + curveHeight + CurveAxisGap - Margin.bottom, left: Margin.left, scale: scaleX, tickFormat: (date) => scaleXFormat(date), tickLabelProps: {
                            fontSize: 14,
                            fontWeight: 400,
                            letterSpacing: '-0.084px',
                            lineHeight: '20px',
                            fill: '#ABB4BB',
                        }, numTicks: 7, hideAxisLine: true, hideTicks: true }), _jsx(Line, { visibility: tooltipOpen ? 'visible' : 'hidden', from: { x: tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.xCoord, y: 0 }, to: { x: tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.xCoord, y: curveHeight + GridLineAdditionalLength }, stroke: theme.palette.primary.dark, strokeDasharray: '4 4', pointerEvents: 'none' }), _jsx(TooltipDotCircle, { isVisible: tooltipOpen && !(tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.isMultiple), x: tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.xCoord, y: tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.yCoord, r: 4 }), _jsx(TooltipDotRect, { isVisible: tooltipOpen && Boolean(tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.isMultiple), x: (tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.ellipseLeft) || 0, y: (tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.ellipseTop) || 0 })] }), _jsx(TooltipStyled, { isVisible: tooltipOpen, style: Object.assign(Object.assign({}, tooltipStyles), { transform: `translate(${tooltipLeft || 0}px, ${tooltipTop || 0}px)` }), gap: `${(tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.yGap) || 0}px ${(tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.xGap) || 0}px`, children: tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.elements.map((element) => (_jsx(TooltipElement, { borderColor: colors[element.walletKey] || 'transparent', percent: element.percent, amount: element.value, portfolioName: element.portfolioName, showPercentage: element.showPercentage }, element.walletKey))) })] }));
};
export default WalletsProfitGraph;
